<template>
  <div>
    <section class="overlay-payment text-center">
      <div class="content white">
        <p v-if="packet.barcode">
          {{ $t("packetDetail.packet") }}
          {{ packet.barcode.substring(0, packet.barcode.length - 3)
          }}<span class="salmon">{{
            packet.barcode.substring(packet.barcode.length - 3)
          }}</span>
        </p>
        <p>
          {{ $t("packetDetail.waiting") }}
        </p>

        <p v-if="isPending" class="blue pt-1">
          {{ $t("packetDetail.pending-payment") }}
        </p>
        <Errors :errors="errors" />

        <h1>{{ formatCod(packet) }}</h1>
        <h2>
          {{
            type == PAYMENT_TYPES.SMS
              ? $t("packetDetail.with-sms")
              : $t("packetDetail.with-card")
          }}
        </h2>
        <a
          href="#"
          class="btn btn-primary btn-white"
          @click.prevent="checkPaymentStatus"
          ><cached-icon :size="24" class="icon" />{{
            $t("packetDetail.verify-payment")
          }}</a
        >
        <a href="#" class="white" @click.prevent="cancelPayment"
          ><close class="close icon" :size="20" />{{
            type == PAYMENT_TYPES.CARD
              ? $t("packetDetail.cancel-payment")
              : $t("packetDetail.cancel")
          }}</a
        >
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { delay } from "lodash";
import Errors from "@/components/Errors";
import CachedIcon from "vue-material-design-icons/Cached.vue";
import Close from "vue-material-design-icons/Close.vue";
import { PAYMENT_TYPES } from "@/store/payment.type";
import { ERRORS } from "@/store/payment.module";
import { formatCod } from "@/mixins/format-cod";

const DELAY_CHECK_STATUS = 10 * 1000;
const REPEAT_CHECK_STATUS = 5 * 1000;

export default {
  name: "OverlayPayment",
  mixins: [formatCod],
  components: { Errors, CachedIcon, Close },

  data() {
    return {
      PAYMENT_TYPES
    };
  },

  props: {
    type: {
      type: String,
      default: PAYMENT_TYPES.CARD,
      required: true
    }
  },

  computed: {
    ...mapState(["errors"]),
    ...mapState("packet", ["packet"]),
    ...mapState("payment", [
      "cardPaymentId",
      "smsPaymentId",
      "transactionFailed",
      "isPending"
    ])
  },

  mounted() {
    this.delayCheckRegularlyPaymentStatus();
  },

  beforeDestroy() {
    this.clearAllTimeouts();
  },

  methods: {
    ...mapActions("payment", [
      "getCardPaymentDetail",
      "cancelCardPayment",
      "getSMSPaymentDetail",
      "cancelSMSPayment"
    ]),
    ...mapMutations("payment", ["resetTransactionFailed"]),
    ...mapMutations(["clearErrors"]),

    async checkPaymentStatus() {
      let isPaid = false;

      switch (this.type) {
        case PAYMENT_TYPES.CARD:
          isPaid = await this.getCardPaymentDetail(this.cardPaymentId);
          break;

        case PAYMENT_TYPES.SMS:
          isPaid = await this.getSMSPaymentDetail(this.smsPaymentId);
          break;
      }

      if (this.transactionFailed) {
        this.clearAllTimeouts();
      }

      this.hideOverlayIfPaid(isPaid);
    },

    delayCheckRegularlyPaymentStatus() {
      this.delayId = delay(() => {
        this.intervalId = setInterval(() => {
          this.checkPaymentStatus();
        }, REPEAT_CHECK_STATUS);
      }, DELAY_CHECK_STATUS);
    },

    async cancelPayment() {
      if (this.transactionFailed) {
        this.resetTransactionFailed();
        this.hideOverlay();

        return;
      }

      if (window.confirm(this.$t("packetDetail.cancel-payment-confirm"))) {
        this.clearAllTimeouts();

        switch (this.type) {
          case PAYMENT_TYPES.CARD:
            await this.cancelCardPayment(this.cardPaymentId);
            break;

          case PAYMENT_TYPES.SMS:
            await this.cancelSMSPayment(this.smsPaymentId);
            break;
        }

        // Always hide overlay
        this.hideOverlay();
      }
    },

    hideOverlay() {
      this.$emit("hideOverlay");

      // We want to show the error on detail if payment cancel fails.
      if (!this.errors.some(e => e.reason === ERRORS.INVALID_STATE)) {
        this.clearErrors();
      }
    },

    hideOverlayIfPaid(isPaid) {
      isPaid && this.hideOverlay();
    },

    clearAllTimeouts() {
      clearTimeout(this.delayId);
      clearInterval(this.intervalId);
    }
  }
};
</script>

<style lang="scss" scoped>
.overlay-payment {
  @include overlay;
  background: rgba(0, 0, 0, 0.95);

  .content {
    max-width: 221px;
    margin: auto;
    margin-top: 108px;

    p {
      font-size: 1rem;
      font-weight: 700;
    }

    h1 {
      font-size: 2.25rem;
      margin-top: 13px;
      font-weight: 700;
    }

    h2 {
      font-size: 1.375rem;
      margin-bottom: 13px;
      text-transform: uppercase;
      font-weight: 700;
    }

    a {
      &:nth-of-type(1) {
        display: inline-block;
        margin-bottom: 46px;
      }

      .icon {
        vertical-align: -6px;
        margin-right: 5px;
      }
    }
  }
}
</style>
