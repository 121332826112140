<template>
  <section>
    <div class="content container white text-center">
      <label>
        {{ $t("packetDetail.select-reason") }}<span class="mandatory">*</span>
        <select v-model="selectedReason">
          <option :value="REASON_TYPES.REFUSED">
            {{ $t("packetDetail.reject-packet") }}
          </option>
          <option :value="REASON_TYPES.NO_FUNDS">
            {{ $t("packetDetail.money") }}
          </option>
          <option :value="REASON_TYPES.OTHER">
            {{ $t("packetDetail.reason") }}
          </option>
        </select>
      </label>

      <form v-if="selectedReason == REASON_TYPES.OTHER">
        <label
          >{{ $t("packetDetail.reason") }}<span class="mandatory">*</span>
          <textarea
            name="textarea"
            v-model="otherReasonText"
            cols="30"
            rows="2"
          ></textarea>
        </label>
      </form>

      <label class="flex-group">
        <input type="checkbox" class="checkbox" v-model="returnToSender" />
        <p>{{ $t("packetDetail.switch-to-return-packet") }}</p>
      </label>

      <a
        href="#"
        :class="[
          'btn btn-primary',
          !otherReasonText && selectedReason == REASON_TYPES.OTHER
            ? 'btn-grey disable'
            : 'btn-red'
        ]"
        @click.prevent="cancelPacketRelease"
      >
        {{ $t("packetDetail.cancel-release") }}
      </a>
      <a href="#" class="white" @click.prevent="hideOverlay" v-if="showBack"
        ><close class="close icon" :size="20" />{{
          $t("packetDetail.cancel")
        }}</a
      >
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import Close from "vue-material-design-icons/Close.vue";

const REASON_TYPES = {
  REFUSED: "refused",
  NO_FUNDS: "no_funds",
  OTHER: "other"
};

export default {
  name: "OverlayCancelDelivery",
  components: { Close },
  props: {
    showBack: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      otherReasonText: null,
      selectedReason: REASON_TYPES.REFUSED,
      returnToSender: false,
      REASON_TYPES
    };
  },

  mounted() {
    this.setDefaultReason();
  },

  computed: {
    ...mapState("packet", ["packet", "searchCode", "readyToPick"]),
    ...mapGetters("packet", ["packetHasUnpaidCod"])
  },

  methods: {
    ...mapActions("packet", ["undeliverPacket"]),

    setDefaultReason() {
      this.selectedReason = this.packetHasUnpaidCod
        ? REASON_TYPES.NO_FUNDS
        : REASON_TYPES.REFUSED;
    },

    hideOverlay() {
      this.$emit("hideOverlay");
    },

    async cancelPacketRelease() {
      const params = {
        barcode: this.packet.barcode,
        password: this.searchCode,
        reason: this.selectedReason,
        return_to_client: this.returnToSender
      };

      if (this.selectedReason == REASON_TYPES.OTHER) {
        params.other_reason = this.otherReasonText;
      }

      await this.undeliverPacket(params);

      this.$router.replace({
        name: "packet-search",
        query: {
          platform: this.platform,
          device: this.device
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  @include overlay;
  background: rgba(0, 0, 0, 0.95);
  padding: 100px 40px 0;

  label {
    text-align: left;
    display: block;
    margin-left: 5px;
    font-weight: 700;
  }

  select {
    margin-top: 2px;
    margin-bottom: 13px;
  }

  textarea {
    margin-bottom: 15px;
  }

  .flex-group {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .checkbox {
      height: 18px;
      width: 18px;
      margin-right: 10px;
      box-shadow: none;
    }
  }

  a {
    &:nth-of-type(1) {
      display: inline-block;
      margin-bottom: 27px;
    }

    .icon {
      vertical-align: -5px;
      margin-right: 2px;
    }
  }
}
</style>
